import { IPixFormValues } from "./PixForm";

export interface IPayerErrors {
  PayerFirstName?: string;
  PayerLastName?: string;
  PayerDocument?: string;
  PayerEmail?: string;
  PayerPhone?: string;
}

export const validate = (
  payer: IPixFormValues,
  setErrors: React.Dispatch<React.SetStateAction<IPayerErrors>>
) => {
  setErrors({
    PayerFirstName: "",
    PayerEmail: "",
    PayerDocument: "",
  });

  let validForm = true;

  if (!payer.PayerFirstName || payer.PayerFirstName.length < 3) {
    setErrors((prev) => ({ ...prev, PayerFirstName: "Nome obrigatório" }));
    validForm = false;
  }

  if (!payer.PayerDocument || payer.PayerDocument.length < 14) {
    setErrors((prev) => ({ ...prev, PayerDocument: "CPF obrigatório" }));
    validForm = false;
  }

  const validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!payer.PayerEmail) {
    setErrors((prev) => ({ ...prev, PayerEmail: "Email obrigatório" }));
    validForm = false;
  } else if (!validEmail.test(payer.PayerEmail)) {
    setErrors((prev) => ({ ...prev, PayerEmail: "Email inválido" }));
    validForm = false;
  }

  return validForm;
};
