import React, { ChangeEvent, FC, useCallback } from "react";
import styles from "./PixForm.module.scss";
import { Container, Grid, TextField } from "@material-ui/core";
import { IPayerErrors } from "./validate";
import { cpfMask } from "cross-cutting/masks/cpf";

export interface IPixFormValues {
  PayerFirstName: string;
  PayerEmail: string;
  PayerPhone: string;
  PayerDocument: string;
}

export interface IPixForm {
  values: IPixFormValues;
  handleChangeValues: (name: string, value: string) => void;
  errors: IPayerErrors;
}

const PixForm: FC<IPixForm> = ({
  values,
  handleChangeValues,
  errors,
}) => {
  const onChangeHandle = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      ev.persist();
      handleChangeValues(ev.target.name, ev.target.name === 'PayerDocument' ? cpfMask(ev.target.value) : ev.target.value);
    },
    [handleChangeValues]
  );

  return (
    <div id={styles.PixForm}>
      <Container maxWidth="xs" className={styles.container}>
        <Grid spacing={2} alignContent="center" alignItems="center" container>
          <Grid item xs={12}>
            <TextField
              required
              autoComplete={"off"}
              helperText={errors.PayerFirstName}
              error={!!errors.PayerFirstName}
              variant="outlined"
              fullWidth
              onChange={onChangeHandle}
              value={values.PayerFirstName}
              name="PayerFirstName"
              label="Nome"
              placeholder="Nome"
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              required
              autoComplete={"off"}
              helperText={errors.PayerPhone}
              error={!!errors.PayerPhone}
              variant="outlined"
              fullWidth
              onChange={onChangeHandle}
              value={values.PayerPhone}
              name="PayerPhone"
              label="Telefone"
              placeholder="Telefone"
              inputProps={{ maxLength: 11 }}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              required
              autoComplete={"off"}
              helperText={errors.PayerDocument}
              error={!!errors.PayerDocument}
              variant="outlined"
              fullWidth
              onChange={onChangeHandle}
              value={values.PayerDocument}
              name="PayerDocument"
              label="CPF"
              placeholder="CPF"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              autoComplete={"off"}
              helperText={errors.PayerEmail}
              error={!!errors.PayerEmail}
              variant="outlined"
              fullWidth
              onChange={onChangeHandle}
              value={values.PayerEmail}
              name="PayerEmail"
              label="E-mail"
              placeholder="E-mail"
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PixForm;
