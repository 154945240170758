export default class Utils {
  public static toDateAndTime(date: Date | string, adjusteUTC?: boolean) {
    date = date instanceof Date ? date : new Date(date);
    const adjustedDate = new Date(new Date(date.getTime() - (3 * 60 * 60 * 1000)).toLocaleString("pt-BR"));
    return `${(adjusteUTC ? adjustedDate : date).toLocaleDateString([], {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })} - ${(adjusteUTC ? adjustedDate : date).toLocaleTimeString([], {
      timeStyle: "short",
      hour12: false
    })}`;
  }
  
  public static toDate(date: Date | string, adjusteUTC?: boolean) {
    date = date instanceof Date ? date : new Date(date);
    const adjustedDate = new Date(new Date(date.getTime() - (3 * 60 * 60 * 1000)).toLocaleString("pt-BR"));
    return `${(adjusteUTC ? adjustedDate : date).toLocaleDateString([], {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })}`;
  }
  
  public static toTime(date: Date | string, adjusteUTC?: boolean) {
    date = date instanceof Date ? date : new Date(date);
    const adjustedDate = new Date(new Date(date.getTime() - (3 * 60 * 60 * 1000)).toLocaleString("pt-BR"));
    return `${(adjusteUTC ? adjustedDate : date).toLocaleTimeString(['pt-br'], {
      timeStyle: "short",
      hour12: false
    })}`
  }
}
